import React, { useState } from "react";
import "./App.css";

function App() {
  const [number, setNumber] = useState(1);
  const [category, setCategory] = useState("gm");

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(parseInt(e.target.value)); // Ensure value is parsed as integer
  };

  const handleChangeCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
  };

  const handleDownload = () => {
    const imageUrl = `https://galacticatsmeme.s3.us-east-2.amazonaws.com/laikaz/${category}/${number}.png`;
    const anchor = document.createElement("a");
    anchor.href = imageUrl;
    anchor.download = "download.png";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  // const toggleDarkMode = () => {
  //   setDarkMode(!darkMode);
  // };

  return (
    <div className={`App`}>
      <h1 className="title">Culture Haus</h1>
      <div>press and hold the image to save or click download</div>
      <div className="settings">
        <input
          type="number"
          className="number-select"
          value={number}
          onChange={handleChangeNumber}
        />
        <select
          name="category"
          className="category-select"
          value={category}
          onChange={handleChangeCategory}
        >
          <option value="gm">GM</option>
          <option value="bag">Bag</option>
          {/* <option value="nova">Nova</option> */}
        </select>
        {/* <button onClick={toggleDarkMode}> */}
          {/* {darkMode ? "Light Mode" : "Dark Mode"} Toggle button text */}
        {/* </button> */}
      </div>
      <div>
        <img
          src={`https://galacticatsmeme.s3.us-east-2.amazonaws.com/laikaz/${category}/${number}.png`}
          alt="Laikaz Meme"
          className="image-container"
          style={{
            border: "10px solid #265F76",
            margin: "auto",
            display: "block",
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <button
          onClick={handleDownload}
          className="button"
          style={{
            border: "20px",
            padding: "10px",
            margin: "10px",
            marginTop: "45px",
            display: "inline-block",
            borderRadius: "12px",
          }}
        >
          Download
        </button>
      </div>
      {/* <div className="creators" >created by cats for cats</div> */}
       <div className="footer">
        <a href="https://x.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
        <a href="https://www.jpg.store/" target="_blank" rel="noopener noreferrer"><i className="fas fa-image"></i></a>
        <a href="https://discord.gg/" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i></a>
        <a href="https://" target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a>
      </div>
    </div>
  );
}

export default App;
